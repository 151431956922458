<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="t('description')"
    />
    <b-row>
      <b-col v-if="showForbiddenList" cols="12" xl="6">
        <global-list
          storeNamespace="ispServices/services"
          v-model="black_list"
          :title="t('FORBIDDEN LIST')"
          :subtitle="forbiddenHelpText"
          action="block"
          :ProductAttention="ProductAttention"
          :RouterSecureAttention="RouterSecureAttention"
          @update_list="(data) => handleUpdateList(data, 'black-list')"
          @get-list="handleGetList"
          searchText='ipdomainurl'
        />
      </b-col>
      <b-col cols="12" xl="6">
        <global-list
          storeNamespace="ispServices/services"
          v-model="white_list"
          :title="t('ALLOWED LIST')"
          :subtitle="allowedHelpText"
          action="allow"
          :ProductAttention="ProductAttention"
          :RouterSecureAttention="RouterSecureAttention"
          @update_list="(data) => handleUpdateList(data, 'white-list')"
          @get-list="handleGetList"
          searchText='ipdomainurl'
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GlobalList from "@/components/IspServices/Services/ForbiddenAllowedLIst/GlobalList";
import { mapActions, mapMutations, mapGetters } from "vuex";
import authorization from "@/mixins/authorization";
import PageTitleCard from "../../../components/AsmConfigurations/PageTitleCard";

export default {
  name: "ForbiddenAllowedLists",
  components: {
    GlobalList,
    PageTitleCard
  },
  mixins: [authorization],
  data() {
    return {
      query: {
        sort: "asc",
        limit: 10,
      },
      service_id: "",
      showForbiddenList: true,
      forbiddenHelpText: "",
      allowedHelpText: "",
      servicesWithoutForbiddenList: ["ads_free", "auto_notice"],
    };
  },
  async created() {
    this.forbiddenHelpText ='forbidden_default_help_text';
    this.allowedHelpText = 'allowed_default_help_text';
    this.update_service();
    switch (this.service_id) {
      case "ads_free":
        this.showForbiddenList = false;
        break;
      case "auto_notice":
        this.showForbiddenList = false;
        break;
    }
    if (this.servicesWithoutForbiddenList.includes(this.service_id)) {
      await this.get_white_list({ query: this.query });
    } else {
      await Promise.all([
        this.get_black_list({ query: this.query }),
        this.get_white_list({ query: this.query }),
      ]);
    }
  },
  computed: {
    ...mapGetters("ispServices/services", ["black_list", "white_list"]),
    ProductAttention() {
      if (this.service_id === "parental_control" || this.service_id === "threat_protection") {
        return {
          title: "* " + this.t("This is not applied by EndpointSecure"),
          operator: "OR",
          products: ["EndpointSecure"],
        };
      }
      return null;
    },
    RouterSecureAttention() {
      const isRSInstalled = this.checkProducts(['RouterSecure']);
      if (this.service_id === "threat_protection" && isRSInstalled) {
        return {
          title: "* " + this.t("Only domains are supported by RouterSecure"),
          operator: "OR",
          products: ["RouterSecure"],
        };
      } else {
        if (this.service_id === "parental_control" && isRSInstalled) {
          return {
            title: "* " + this.t("This is not applied by RouterSecure"),
            operator: "OR",
            products: ["RouterSecure"],
          };
        }
      }
      return null;
    },
  },
  methods: {
    ...mapMutations("ispServices/services", ["update_current_service_id"]),
    ...mapActions("ispServices/services", [
      "get_black_list",
      "get_white_list",
      "update_black_list",
      "update_white_list",
    ]),
    handleGetList(list_type, query) {
      if( !query.hasOwnProperty('limit') ) query = this.query;
      if (list_type === "allow") this.get_white_list({ query });
      else if (!this.servicesWithoutForbiddenList.includes(this.service_id)) {
        this.get_black_list({ query });
      }
    },
    handleUpdateList(data, listType) {
      if (listType === 'black-list') {
        !this.white_list.includes(data.item)
          ? this.update_black_list({...data, query: data.query || query})
          : this.$store.dispatch('setError', this.t('white_list_contains_url'), { root: true });
      }
      else if (listType === 'white-list') {
        !this.black_list.includes(data.item)
          ? this.update_white_list({...data, query: data.query || query})
          : this.$store.dispatch('setError', this.t('black_list_contains_url'), { root: true });
      }
    },
    update_service() {
      this.service_id = this.$route.params.serviceName;
      this.update_current_service_id(this.service_id);
    },
  },
};
</script>
